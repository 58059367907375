











































import Vue from "vue";
import { QrcodeStream } from "vue-qrcode-reader";
import { Timer } from "easytimer.js";

export default Vue.extend({
  data() {
    return {
      loading: true,
      session: null as null | string,
      timer: new Timer(),
      timerValue: "",
    };
  },
  components: {
    QrcodeStream,
  },
  methods: {
    async onInit(promise: any) {
      // show loading indicator
      this.loading = true;
      try {
        const { capabilities } = await promise;
        console.log(capabilities);
        // successfully initialized
      } catch (error: any) {
        console.log("e", error);
        if (error.name === "NotAllowedError") {
          // user denied camera access permisson
        } else if (error.name === "NotFoundError") {
          // no suitable camera device installed
        } else if (error.name === "NotSupportedError") {
          // page is not served over HTTPS (or localhost)
        } else if (error.name === "NotReadableError") {
          // maybe camera is already in use
        } else if (error.name === "OverconstrainedError") {
          // did you requested the front camera although there is none?
        } else if (error.name === "StreamApiNotSupportedError") {
          // browser seems to be lacking features
        }
      } finally {
        this.loading = false;
        // hide loading indicator
      }
    },
    onDecode(decoded: string): void {
      this.session = decoded;
      console.log(decoded);
      this.timer.start();
      this.timerValue = this.timer.getTimeValues().toString();
      setInterval(() => {
        this.timerValue = this.timer.getTimeValues().toString();
      }, 1000);
    },
    leaveSession() {
      this.session = null;
    },
  },
  mounted() {
    setTimeout(() => {
      this.onDecode("test123");
    }, 20000);
  },
});
